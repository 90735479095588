/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import "./index.style.scss";
import "modern-normalize/modern-normalize.css";
import { FinanceService, SessionService } from "@tgg/shared";
import { RedBlackService } from "./services/redblack/redblack.service";
import App from "./app";
import React from "react";
import ReactDOM from "react-dom";

// TODO: Move to config
const baseUri = "https://dev-api.nocturnestudios.games";
// const baseUri = "http://localhost:15000";
const redBlackService = new RedBlackService(`${baseUri}/api/RedBlack`);
const sessionService = new SessionService(`${baseUri}/api`);
const financeService = new FinanceService(`${baseUri}/hubs/finance`);

declare let leanderGMApi: any;

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
initLeander();

function initLeander() {
    if (leanderGMApi && leanderGMApi.initialized) {
        initGame();
    } else {
        setTimeout(() => {
            initLeander();
        }, 1000);
    }
}

function initGame() {
    console.log("Initialized RedBlack");

    const executeActionOnErrorHandled = (error: any) => {
        // Read error data from object returned
        console.log(error);

        const errorOccurred = error.data;
        // If a custom action is sent, execute it
        if (errorOccurred.action) {
            errorOccurred.action();
        }
    };

    leanderGMApi.subscribeToEvent(
        leanderGMApi.requestStatus.ERROR_HANDLED,
        executeActionOnErrorHandled
    );

    leanderGMApi.subscribeToEvent(
        leanderGMApi.requestStatus.ERROR_OCCURRED,
        executeActionOnErrorHandled
    );

    const serverRequestFailed = leanderGMApi.subscribeToEvent(leanderGMApi.requestStatus.REQUEST_FAILED, (e: any) => {
        console.log(e);
    });

    console.log(`SessionId:  ${leanderGMApi.getSessionId()}`);
    console.log(leanderGMApi.sessionData.sessionIdReady);

    leanderGMApi.subscribeToEvent(
        leanderGMApi.requestStatus.SESSION_DATA_UPDATED,
        (response: any) => {
            console.log("SESSION_DATA_UPDATED");

            console.log(response);

            const responseData = response.data;
            // Get the valid sessionId
            const sessionId = responseData.sessionId;
            console.log(`SessionId: ${sessionId} ${leanderGMApi.getSessionId()}`);
            alert(response);
            // new MainApp();

            // Unsubscribe to session request id
            // leanderGMApi.unsubscribeFromEvent(subscribeToSessionIdEvent);
            // Get values from LeanderGMApi
        }
    );

    const subscribeInitConfirmation = leanderGMApi.subscribeToEvent(
        leanderGMApi.publications.INITIALIZED_CONFIRMED, (s: any) => {
            console.log("INITIALIZED_CONFIRMED?", s);

            leanderGMApi.unsubscribeFromEvent(subscribeInitConfirmation);

            leanderGMApi.sendSessionRequest();

            setTimeout(() => {
                leanderGMApi.publishEvent(
                    leanderGMApi.publications.PRELOADING_ENDED
                );
            }, 5000);

            // Start initialization of the game
            //    initializeGame(); // code to initialize
        }
    );

    leanderGMApi.publishEvent(leanderGMApi.publications.INITIALIZED);
    // leanderGMApi.publishEvent(leanderGMApi.publications.GAME_STATUS_CHANGED, leanderGMApi.gameStates.INITIALIZE)

    console.log("Sent");
    ReactDOM.render(
        <React.StrictMode>
            <App
                sessionService={sessionService}
                redBlackService={redBlackService}
                financeService={financeService}
            />
        </React.StrictMode>,
        document.getElementById("root")
    );
}
