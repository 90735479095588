import { HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { LazyAsync } from "../../common";
import { ResultSet } from "./interfaces/result-set.interface";
import { ServiceException } from "./exceptions/service-exception";
import { SignalDispatcher, SimpleEventDispatcher } from "strongly-typed-events";
import { WsRPCExceptionDto, WsRPCRequestDto, WsRPCResponseDto } from "./dto";
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelToken, Method } from "axios";

const SocketTimeout = 5000;
export abstract class BaseService {
    private websocket: LazyAsync<HubConnection>;
    private websocketRPCCounter = 0;
    private websocketTimer?: ReturnType<typeof setTimeout>;
    private websocketEvents: { [index: string]: (e: any) => void } = {};
    private instance: AxiosInstance;
    private baseUrl: string;
    private lastToken?: string;

    public constructor(baseUrl?: string) {
        this.instance = axios.create();
        this.baseUrl = baseUrl ? baseUrl : "";
        this.websocket = new LazyAsync(() => this.connectSocket());
    }

    public async connectWebSocket(): Promise<boolean> {
        try {
            return !!(await this.getSocket());
        } catch (error) {
            return false;
        }
    }

    protected async getSocket(): Promise<HubConnection> {
        do {
            try {
                const socket = await this.websocket.get();
                if (socket.state === HubConnectionState.Disconnected) {
                    this.websocket.clear();
                    continue;
                }

                const newToken = this.getToken();
                if (newToken !== this.lastToken) {
                    await socket.stop();
                    this.websocket.clear();
                    this.lastToken = newToken;
                    continue;
                }

                return socket;
            } catch (error) {
                await new Promise((resolve) => setTimeout(resolve, 1000));
            }
        } while (true);
    }

    protected onWebSocketConnected(socket: HubConnection): void {
        for (const event in this.websocketEvents) {
            if (Object.prototype.hasOwnProperty.call(this.websocketEvents, event)) {
                const callback = this.websocketEvents[event];
                socket.on(event, callback);
            }
        }

        this.websocketCheckTick();
    }

    protected onWebSocketConnecting(): void {
        // do nothing
    }

    protected registerSocketEvent<T>(event: string, callback: SimpleEventDispatcher<T> | SignalDispatcher | ((response: T) => void)): void {
        if (this.websocket.value != null) {
            throw new Error("Too late for event registration.");
        }

        if (typeof callback != "function") {
            const dispatcher = callback;
            callback = (e) => dispatcher.dispatch(e);
        }

        this.websocketEvents[event] = callback;
    }

    protected async sendWebSocket<TRequestBody = undefined>(event: string, body?: TRequestBody): Promise<void> {
        const socket = await this.getSocket();
        void socket.invoke(event, ...(body ? [ body ] : []));
    }

    protected requestWebSocket<
        TResponse = void,
        TRequest extends WsRPCRequestDto = WsRPCRequestDto,
        TRequestBody = Omit<TRequest, "key">,
    >(
        event: string,
        body?: TRequestBody
    ): Promise<TResponse> {
        return new Promise<TResponse>(
            (resolve, reject) => {
                const operation = async () => {
                    const key = (++this.websocketRPCCounter).toString();
                    let hasResolved = false;
                    const socketClient = await this.getSocket();
                    const onResponse = (rpcResponse: WsRPCResponseDto<TResponse> | WsRPCExceptionDto) => {
                        if (rpcResponse.key !== key || hasResolved) {
                            return;
                        }

                        hasResolved = true;
                        socketClient.off("exception", onResponse);
                        socketClient.off(event + "_RESULT", onResponse);
                        if (timeout) {
                            clearTimeout(timeout);
                        }

                        if (rpcResponse.statusCode !== 200 || typeof (rpcResponse as WsRPCResponseDto<TResponse>).result === "undefined") {
                            reject(new ServiceException((rpcResponse as WsRPCExceptionDto).error ?? rpcResponse.message, rpcResponse.statusCode));
                        } else {
                            resolve((rpcResponse as WsRPCResponseDto<TResponse>).result);
                        }
                    };

                    const timeout = setTimeout(
                        () => {
                            if (hasResolved) {
                                return;
                            }

                            hasResolved = true;
                            socketClient.off("exception", onResponse);
                            socketClient.off(event + "_RESULT", onResponse);
                            reject(new ServiceException("Request timed out."));
                        },
                        SocketTimeout
                    );

                    const request: TRequestBody & WsRPCRequestDto = {
                        ...(body ?? {}) as TRequestBody,
                        key,
                    };

                    socketClient.on("exception", onResponse);
                    socketClient.on(event + "_RESULT", onResponse);
                    void socketClient.invoke(event, request);
                };

                // fire and forget
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const _ = operation();
            }
        );
    }

    protected async requestRest<
        TResponse = void,
        TRequestBody = undefined,
        TResponseBody = TResponse extends Array<infer U> ? U[] : TResponse
    >(
        path: string,
        parameters: Record<string | number | symbol, string | number | boolean>,
        queries: Record<string | number | symbol, string | number | boolean | Date | undefined | null | Array<string | number | boolean | Date | null>>,
        method: Method,
        requestBody?: TRequestBody,
        cancelToken?: CancelToken
    ): Promise<TResponseBody> {
        let url = this.baseUrl + path.trim();

        for (const parameterName in parameters) {
            if (Object.prototype.hasOwnProperty.call(parameters, parameterName)) {
                if (typeof parameters[parameterName] === "undefined") {
                    continue;
                }

                url = url.replace("{" + parameterName + "}", parameters[parameterName].toString());
            }
        }

        let isFirstQuery = true;
        for (const queryName in queries) {
            if (Object.prototype.hasOwnProperty.call(queries, queryName)) {
                let queryValue = queries[queryName];
                if (typeof queryValue !== "undefined") {
                    if (!Array.isArray(queryValue)) {
                        if (isFirstQuery) {
                            isFirstQuery = false;
                            url += "?";
                        } else {
                            url += "&";
                        }
                        if (queryValue instanceof Date) {
                            queryValue = queryValue.toISOString();
                        }
                        url += encodeURIComponent(queryName) + "=" + encodeURIComponent(queryValue ?? "null");
                    } else {
                        for (let value of queryValue) {
                            if (isFirstQuery) {
                                isFirstQuery = false;
                                url += "?";
                            } else {
                                url += "&";
                            }
                            if (value instanceof Date) {
                                value = value.toISOString();
                            }
                            url += encodeURIComponent(queryName) + "[]=" + encodeURIComponent(value ?? "null");
                        }
                    }
                }
            }
        }

        const token = this.getToken();
        const request: AxiosRequestConfig = {
            data: requestBody,
            method,
            url,
            headers: {
                ...(requestBody ? { "Content-Type": "application/json" } : {}),
                ...(token ? { Authorization: "Bearer " + token } : {}),
                Accept: "text/plain",
            },
            cancelToken,
        };

        let offset: number | undefined;
        let total: number | undefined;

        const response: AxiosResponse<TResponseBody> | undefined = await this.instance.request(request).catch(
            (error: unknown) => {
                // this.logger.debug(error);

                if (!!error && typeof error === "object" && (error as AxiosError).isAxiosError === true) {
                    return (error as AxiosError).response;
                }

                throw error;
            }
        );

        const headers: { [key: string]: unknown } = {};
        if (response?.headers && typeof response.headers === "object") {
            const responseHeaders = response.headers as { [key: string]: unknown };
            // console.log(responseHeaders);
            for (const k in responseHeaders) {
                if (responseHeaders.hasOwnProperty(k)) {
                    const value = responseHeaders[k];
                    headers[k] = value;

                    if (typeof value !== "string") {
                        continue;
                    }

                    if (k.toLowerCase().trim() === "x-total") {
                        total = parseInt(value, 10);
                    } else if (k.toLowerCase().trim() === "x-offset") {
                        offset = parseInt(value, 10);
                    }
                }
            }
        }

        if (response?.status === 200 || response?.status === 201) {
            try {
                if (Array.isArray(response.data)) {
                    return (
                        {
                            result: response.data as unknown,
                            offset: offset ?? 0,
                            total: total ?? response.data.length,
                        } as ResultSet<any>
                    ) as unknown as TResponseBody;
                }

                return (response.data as unknown as TResponse) as unknown as TResponseBody;
            } catch (error) {
                // this.logger.debug(error);
            }
        }

        const exception = new ServiceException("An unexpected server error occurred.", response?.status, response?.data as unknown as string, headers);
        throw exception;
    }

    protected getToken(): string | undefined {
        return undefined;
    }

    private websocketCheckTick() {
        if (this.websocketTimer) {
            clearTimeout(this.websocketTimer);
        }

        this.websocketTimer = setTimeout(
            () => {
                // fire and forget
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const _ = this.getSocket()
                    .catch(() => undefined)
                    .then(
                        () => {
                            this.websocketCheckTick();
                        }
                    );
            },
            1000
        );
    }

    private async connectSocket(): Promise<HubConnection> {
        this.onWebSocketConnecting();
        const socketClient = new HubConnectionBuilder()
            .withUrl(
                this.baseUrl,
                {
                    accessTokenFactory: () => {
                        this.lastToken = this.getToken();
                        return this.lastToken ?? "";
                    },
                }
            )
            .withAutomaticReconnect()
            .build();
        await socketClient.start();
        this.onWebSocketConnected(socketClient);
        return socketClient;
    }
}
