/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import * as React from "react";
import { AppProperties } from "./app.properties";
import { GameConfiguration, GameTypes } from "@tgg/shared";
import getSymbolFromCurrency from "currency-symbol-map";
import RedBlackPage from "./components/redblack-page/redblack.page";

declare let leanderGMApi: any;

export default class App extends React.PureComponent<AppProperties, {
    userId?: number;
    screenHeight?: number;
    balance?: number;
    currencySign?: string;
    currency?: string;
    config?: GameConfiguration;
}> {
    public constructor(props: AppProperties) {
        super(props);
        this.state = { };
    }

    public initGame(): void {
        console.log("initGame");

        if (leanderGMApi) {
            if (leanderGMApi && leanderGMApi.initialized) {
                console.log("Initialized RedBlack");

                const executeActionOnErrorHandled = (error: any) => {
                    // Read error data from object returned
                    console.log(error);

                    const errorOccurred = error.data;
                    // If a custom action is sent, execute it
                    if (errorOccurred.action) {
                        errorOccurred.action();
                    }
                };

                leanderGMApi.subscribeToEvent(
                    leanderGMApi.requestStatus.ERROR_HANDLED,
                    executeActionOnErrorHandled
                );

                leanderGMApi.subscribeToEvent(
                    leanderGMApi.requestStatus.ERROR_OCCURRED,
                    executeActionOnErrorHandled
                );

                const serverRequestFailed = leanderGMApi.subscribeToEvent(leanderGMApi.requestStatus.REQUEST_FAILED, (e: any) => {
                    console.log(e);
                });

                console.log(`SessionId:  ${leanderGMApi.getSessionId()}`);
                console.log(leanderGMApi.sessionData.sessionIdReady);

                leanderGMApi.subscribeToEvent(
                    leanderGMApi.requestStatus.SESSION_DATA_UPDATED,
                    (response: any) => {
                        console.log("SESSION_DATA_UPDATED");

                        console.log(response);

                        const responseData = response.data;
                        // Get the valid sessionId
                        const sessionId = responseData.sessionId;
                        console.log(`SessionId: ${sessionId} ${leanderGMApi.getSessionId()}`);
                        alert(response);
                        // new MainApp();

                        // Unsubscribe to session request id
                        // leanderGMApi.unsubscribeFromEvent(subscribeToSessionIdEvent);
                        // Get values from LeanderGMApi
                    }
                );

                const subscribeInitConfirmation = leanderGMApi.subscribeToEvent(
                    leanderGMApi.publications.INITIALIZED_CONFIRMED, (s: any) => {
                        console.log("INITIALIZED_CONFIRMED?", s);

                        leanderGMApi.unsubscribeFromEvent(subscribeInitConfirmation);

                        leanderGMApi.sendSessionRequest();

                        // Start initialization of the game
                        //    initializeGame(); // code to initialize
                    }
                );

                leanderGMApi.publishEvent(leanderGMApi.publications.INITIALIZED);
                // leanderGMApi.publishEvent(leanderGMApi.publications.GAME_STATUS_CHANGED, leanderGMApi.gameStates.INITIALIZE)

                console.log("Sent");
            } else {
                setTimeout(() => {
                    this.initGame();
                }, 1000);
            }
        }
    }

    public componentDidMount(): void {
        const url = new URL(window.location.href);
        const marbleId = url.searchParams.get("playerToken");

        this.initGame();

        if (marbleId) {
            this.props.sessionService.getOrCreateSession(marbleId, GameTypes.RedBlack).then(
                (s) => {
                    this.props.financeService.onBalanceUpdated.sub(
                        (b) => this.setState(
                            {
                                balance: b.amount,
                                currency: b.currency,
                                currencySign: getSymbolFromCurrency(b.currency ?? "") ?? `${b.currency} `,
                            }
                        )
                    );
                    this.props.redBlackService.setToken(s.token);
                    this.props.financeService.setToken(s.token);
                    void this.props.financeService.connectWebSocket();
                    this.setState(
                        {
                            userId: s.userId,
                            config: s.config ?? undefined,
                        }
                    );
                },
                (e: string | Error) => alert(`Failed to authenticate: ${e.toString()}`)
            );
        }

        window.addEventListener("resize", this.onResize);
        this.onResize();
    }

    public componentWillUnmount(): void {
        this.props.financeService.onBalanceUpdated.clear();
        window.removeEventListener("resize", this.onResize);
    }

    public render(): React.ReactNode {
        return <RedBlackPage
            userId={this.state.userId}
            redblackService={this.props.redBlackService}
            balance={this.state.balance}
            currency={this.state.currency}
            currencySign={this.state.currencySign}
            config={this.state.config}
            style={{ ["--screen-height" as any]: this.state.screenHeight ? `${this.state.screenHeight}px` : "100vh" }}
        />;
    }

    private onResize = () => {
        this.setState(
            {
                screenHeight: window.innerHeight,
            }
        );
    };
}
