import "./redblack-bet-placement.style.scss";
import { AccountingHelper, AudioHelper } from "@tgg/shared";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { RedBlackContext, RedBlackContextValue } from "../../contexts/redblack.context";
import { RedBlackHandPrediction } from "../../services/redblack/enums/redblack-hand-prediction.enum";
import classNames from "classnames";
import Picker from "react-mobile-picker";
import React, { ReactNode } from "react";

const StepMultipliers = [
    1,
    5,
    10,
    20,
    50,
    100,
    150,
    300,
    500,
    1000,
    2500,
    5000,
    10000,
    20000,
];

const StepCurrencies: Record<string, number> = {
    GBP: 10,
    GHS: 100,
    MGA: 50000,
    GEL: 25,
    AUD: 10,
    BRL: 50,
    CAD: 10,
    CNY: 100,
    EUR: 10,
    INR: 1000,
    MXN: 200,
    PLN: 50,
    RUB: 1000,
    SEK: 100,
    USD: 10,
    CHF: 10,
    HKD: 100,
    DKK: 100,
    RMB: 100,
    JPY: 10,
    NOK: 100,
    SGD: 20,
    IDR: 1000,
    ZAR: 200,
    TRY: 200,
};

export class RedBlackBetPlacement extends React.PureComponent<unknown, {
    amount?: number;
}> {
    public static contextType = RedBlackContext;
    public context!: RedBlackContextValue;
    private chipAudio: HTMLAudioElement | undefined;
    private lastMatchId?: number;
    private wonLastHand = false;

    public constructor(props: unknown) {
        super(props);
        this.state = { };
    }

    public componentDidMount(): void {
        if (!this.chipAudio) {
            void AudioHelper.loadAudio("/redblack-chip-drop.mp3").then(
                (a) => this.chipAudio = a
            );
        }
    }

    public componentDidUpdate(): void {
        if (
            this.lastMatchId !== this.context.match?.id ||
            (this.wonLastHand && !this.context.lastHand?.won) ||
            this.state.amount == null
        ) {
            this.lastMatchId = this.context.match?.id;

            this.setState(
                {
                    amount: this.context.minDeposit,
                }
            );
        }

        if (this.wonLastHand !== this.context.lastHand?.won) {
            this.wonLastHand = this.context.lastHand?.won ?? false;
        }
    }

    public render(): ReactNode {
        return <section className="redblack-bet-placement">
            {this.renderHandStatus()}
            {this.renderBetControls()}
            {this.renderBetButtons()}
        </section>;
    }

    private renderHandStatus(): ReactNode {
        return <div className="redblack-bet-placement-status">
            <ul>
                {
                    new Array(5).fill(0).map(
                        (_, i) => {
                            const hand = this.context.match?.hands && typeof this.context.match?.hands[i] != "undefined" ? this.context.match?.hands[i] : undefined;
                            let className: string | undefined;
                            switch (hand?.prediction) {
                                case RedBlackHandPrediction.Black:
                                    className = "redblack-bet-placement-status-black";
                                    break;
                                case RedBlackHandPrediction.Red:
                                    className = "redblack-bet-placement-status-red";
                                    break;
                            }
                            return <li key={i} className={className} />;
                        }
                    )
                }
            </ul>
        </div>;
    }

    private renderBetButtons(): ReactNode {
        const hasPlayed = this.context.currentHand > this.context.visibleHand;
        const canPlay = this.canSubmit();
        const hasEnded = this.context.match?.ended != null;
        const hasInsufficientFunds = this.getMaxBet() < this.getMinBet();

        return <SwitchTransition
            mode="out-in"
        >
            <CSSTransition
                key={hasPlayed ? "played" : "playing"}
                classNames="fade"
                timeout={300}
            >
                <>
                    {
                        !hasPlayed && !hasEnded && !hasInsufficientFunds && <div className="redblack-bet-placement-buttons">
                            <button
                                className="redblack-bet-placement-button-red"
                                disabled={this.context.isBusy || this.context.isDisabled || !canPlay}
                                onClick={() => this.submit(RedBlackHandPrediction.Red)}
                            >Red</button>
                            <button
                                className="redblack-bet-placement-button-black"
                                disabled={this.context.isBusy || this.context.isDisabled || !canPlay}
                                onClick={() => this.submit(RedBlackHandPrediction.Black)}
                            >Black</button>
                        </div>
                    }
                    {
                        !hasEnded && !hasInsufficientFunds && hasPlayed && <div className="redblack-bet-placement-buttons">
                            <button onClick={this.context.endHand}>PLAY NEXT HAND</button>
                        </div>
                    }
                    {
                        !hasEnded && hasInsufficientFunds && <div className="redblack-bet-placement-buttons">
                            <button onClick={this.context.endMatch}>Insufficient Funds</button>
                        </div>
                    }
                    {
                        hasEnded && <div className="redblack-bet-placement-buttons">
                            <button onClick={this.context.endMatch}>PLAY ANOTHER ROUND</button>
                        </div>
                    }
                </>
            </CSSTransition>
        </SwitchTransition>;
    }

    private renderBetControls(): ReactNode {
        const hasPlayed = this.context.currentHand > this.context.visibleHand;
        return <div className="redblack-bet-placement-amount">
            <SwitchTransition
                mode="out-in"
            >
                <CSSTransition
                    key={hasPlayed ? "played" : "playing"}
                    classNames="fade"
                    timeout={300}
                >
                    <>
                        {
                            !hasPlayed && <Picker
                                optionGroups={
                                    {
                                        Bet: this.getSteps().map((b) => `${this.context.currencySign ?? "$"}${AccountingHelper.formatPrice(b, this.context.decimals) }`),
                                    }
                                }
                                valueGroups={
                                    {
                                        Bet: `${this.context.currencySign ?? "$"}${AccountingHelper.formatPrice(this.state.amount ?? 0, this.context.decimals) }`,
                                    }
                                }
                                onChange={this.setAmount}
                                itemHeight={36}
                                height={168}
                                wheel="normal"
                            />
                        }
                        {
                            hasPlayed && <h5
                                className={
                                    classNames(
                                        {
                                            ["redblack-bet-placement-status-message-red"]: this.context.lastHand?.prediction === RedBlackHandPrediction.Red,
                                            ["redblack-bet-placement-status-message-black"]: this.context.lastHand?.prediction === RedBlackHandPrediction.Black,
                                        }
                                    )
                                }
                            >
                                {!this.context.lastHand?.won && `You picked ${this.context.lastHand?.prediction === RedBlackHandPrediction.Black ? "Black" : "Red"}`}
                                {this.context.lastHand?.won && "YOU WON !"}
                            </h5>
                        }
                    </>
                </CSSTransition>
            </SwitchTransition>
        </div>;
    }

    private formatValue(v?: number) {
        return `${this.context.currencySign ?? "$"}${AccountingHelper.formatPrice(v ?? 0, this.context.decimals)}`;
    }

    private setAmount = (_name: string, value: string) => {
        const hasPlayed = this.context.currentHand > this.context.visibleHand;
        const isDisabled = hasPlayed || this.context.isBusy || this.context.isDisabled;

        if (isDisabled || !value) {
            return;
        }

        const amount = this.getSteps().find((s) => this.formatValue(s) === value);
        if (!amount || amount < 0) {
            return;
        }

        this.setState(
            {
                amount,
            }
        );
    };

    private getMaxBet(): number {
        if (this.context.balance != null) {
            return Math.min(
                this.context.balance,
                Math.min(Math.trunc(this.context.maxDeposit / Math.pow(2, (this.context.match?.remainingHands ?? 5) - 1)), this.context.maxDeposit)
            );
        } else {
            return this.context.minDeposit;
        }
    }

    private getMinBet(): number {
        if (this.context.lastHand?.amount != null && this.context.lastHand.won && this.context.match?.remainingHands !== 0) {
            return this.context.lastHand.amount * 2;
        }
        return this.context.minDeposit;
    }

    private getSteps(): number[] {
        const currencyStep = (!!this.context.currency && typeof StepCurrencies[this.context.currency] !== "undefined") ? StepCurrencies[this.context.currency] : 1;
        const digits = Math.floor(Math.log10(currencyStep));

        const minBet = this.getMinBet();
        const maxBet = this.getMaxBet();
        const steps: number[] = [];
        const start = Math.max(Math.min(Math.round(minBet / currencyStep) * currencyStep, maxBet), minBet);
        if (start !== minBet) {
            steps.push(minBet);
        }

        steps.push(start);

        const scale = Math.pow(10, digits);
        steps.push(
            ...StepMultipliers
                // .map((m) => Math.round(m / currencyStep) * currencyStep)
                .map((m) => m * scale)
                .filter((m) => m > start && m < maxBet)
        );

        if (!steps.includes(maxBet)) {
            steps.push(maxBet);
        }

        return steps;
    }

    private canSubmit(): boolean {
        if (this.context?.balance == null || this.state.amount == null) {
            return false;
        }

        if (this.getMinBet() > this.getMaxBet()) {
            return false;
        }

        if (this.state.amount < this.getMinBet()) {
            return false;
        }

        if (this.state.amount > this.getMaxBet()) {
            return false;
        }

        return true;
    }

    private submit(prediction: RedBlackHandPrediction) {
        if (!this.canSubmit()) {
            return;
        }

        if (!this.context.predictHand) {
            return;
        }

        this.context.predictHand(prediction, this.state.amount!);
    }
}
