export abstract class AccountingHelper {
    public static formatPrice(value: number | string | undefined | null, decimals = 2): string {
        if (value == null) {
            return "-";
        }

        if (typeof value !== "number") {
            value = parseFloat(value.toString());
        }

        if (value === 0) {
            return "FREE";
        }

        value /= 100;
        return value.toLocaleString(
            undefined,
            {
                maximumFractionDigits: decimals,
                minimumFractionDigits: decimals,
                // maximumSignificantDigits: 3,
            }
        );
    }

    public static formatMoney(value: number | string | undefined | null, decimals = 2): string {
        if (value == null) {
            return "-";
        }

        if (typeof value !== "number") {
            value = parseFloat(value.toString());
        }
        value /= 100;
        return value.toLocaleString(
            undefined,
            {
                maximumFractionDigits: decimals,
                minimumFractionDigits: decimals,
            }
        );
    }

    public static formatMoneyCompact(value: number | string | undefined | null, decimals = 1): string {
        if (value == null) {
            return "-";
        }

        if (typeof value !== "number") {
            value = parseFloat(value.toString());
        }

        let postfix = "";

        if (value > 1000) {
            value /= 1000;
            postfix = "K";
        }

        if (value > 1000) {
            value /= 1000;
            postfix = "M";
        }

        if (value > 1000) {
            value /= 1000;
            postfix = "B";
        }

        if (value > 1000) {
            value /= 1000;
            postfix = "T";
        }

        return value.toLocaleString(
            undefined,
            {
                maximumFractionDigits: decimals,
                minimumFractionDigits: 0,
            }
        ) + postfix;
    }

    public static formatBalance(value: number | string | undefined | null, decimals = 2): string {
        if (value == null) {
            return "-";
        }

        if (typeof value !== "number") {
            value = parseFloat(value.toString());
        }
        value /= 100;
        return value.toLocaleString(
            undefined,
            {
                maximumFractionDigits: decimals,
                minimumFractionDigits: decimals,
            }
        );
    }

    public static formatNumber(value: number | string | undefined | null): string {
        if (value == null) {
            return "-";
        }

        if (typeof value !== "number") {
            value = parseFloat(value.toString());
        }

        return value.toLocaleString(
            undefined,
            {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
            }
        );
    }
}
